import "@/css/app.pcss";

import Alpine from "alpinejs";

import "what-input";
import "lazysizes";
import "cookies.js";
import './lib/liteYoutube.ts'

import intersect from '@alpinejs/intersect';
Alpine.plugin(intersect);

import focus from '@alpinejs/focus'
Alpine.plugin(focus)

import justifiedLayout from 'justified-layout'
window.justifiedLayout = justifiedLayout;

window.Alpine = Alpine;

import mediumZoom from 'medium-zoom'
window.mediumZoom = mediumZoom('[data-zoomable]', {
  background: "rgba(0,0,0,0.75)",
  margin: 10,
  zIndex: 9999
})

// Swiper JS
import Swiper, { Navigation, Pagination, Thumbs } from "swiper";
Swiper.use([Navigation, Pagination, Thumbs]);

const swiperThumbs = new Swiper(".swiper-thumbs", {
  loop: true,
  spaceBetween: 10,
  slidesPerView: 3,
  freeMode: true,
  watchSlidesProgress: true,
  breakpoints: {
    1024: {
      slidesPerView: 4,
    },
  },
});

const swiper = new Swiper(".swiper-no-thumbs", {
  loop: true,
  autoHeight: false,
  spaceBetween: 0,

  pagination: {
    el: ".swiper-pagination",
    type: "progressbar",
  },

  // Navigation arrows
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev"
  },
});

const swiperWithThumbs = new Swiper(".swiper-with-thumbs", {
  loop: true,
  autoHeight: false,
  spaceBetween: 0,

  // Navigation arrows
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev"
  },

  thumbs: {
    swiper: swiperThumbs
  }
});

Alpine.data('popup', () => ({
  showPopup: false,

  setCookie(cookieName, noOfDays) {
    let seconds = noOfDays * 24 * 60 * 60;
    let customName = cookieName;
    docCookies.setItem(customName, 'closed', seconds);
  }
}));

Alpine.data('hero', () => ({
  activeImage: 1,

  setHeight() {
    let el = this.$el;
    let parent = el.parentNode;
    let parentHt = parent.clientHeight + "px";
    console.log(parentHt);
    el.style.height = parentHt;
  }
}));

Alpine.start();
